<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left>
        الملف الشخصي
      </template>
      <template #right> </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-6">
        <div class="mb-3">
          <label for="userName" class="form-label required">
            اسم الموظف
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="userName"
            v-model="body.name"
            disabled
            :class="{ 'is-invalid': isInvalid && !body.name }"
            required
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="email" class="form-label required">
            البريد الالكتروني
            <span>*</span>
          </label>
          <input
            type="email"
            class="form-control"
            id="email"
            disabled
            v-model="body.email"
            :class="{ 'is-invalid': isInvalid && !body.email }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="userName" class="form-label required">
            اسم المستخدم
            <span>*</span>
          </label>
          <input
            type="text"
            class="form-control"
            id="userName"
            disabled
            v-model="body.userName"
            :class="{ 'is-invalid': isInvalid && !body.userName }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label required">
            الهاتف
            <span>*</span>
          </label>
          <input
            type="tel"
            class="form-control"
            id="phone"
            v-model="body.phone"
            :class="{ 'is-invalid': isInvalid && !body.phone }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="password" class="form-label required">
            كلمه المرور
            <span>*</span>
          </label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="body.password"
            :class="{ 'is-invalid': isInvalid && !body.password }"
            required
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="password" class="form-label required">
            تأكيد كلمه المرور
            <span>*</span>
          </label>
          <input
            type="password"
            class="form-control"
            id="password"
            v-model="body.confermedPassword"
            :class="{
              'is-invalid':
                (isInvalid && !body.confermedPassword) ||
                body.password != body.confermedPassword,
            }"
            required
          />
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3 text-center">
          <Button
            label="تعديل"
            icon="pi pi-pencil" v-tooltip="'تعديل'"
            class="p-ml-2 p-button-info"
            @click="update()"
            :disabled="disabledAdd"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      body: {
        userName: null,
        email: null,
        phone: null,
        userNotes: null,
        name: null,
        password: null,
        confermedPassword: null,
      },
      id: null,

      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    update() {
      if (this.body.userName && this.body.email && this.body.name) {
        this.disabledAdd = true;

        if (this.body.password == null || this.body.password == '') {
          delete this.body.confermedPassword;
          delete this.body.password;
          this.$http.put(`users/${this.id}`, this.body).then(
            () => {
              this.$toast.add({
                severity: 'success',
                summary: 'تم بنجاح',
                detail: 'تم التعديل بنجاح',
                life: 3000,
              });
              this.disabledAdd = false;

              this.$router.push('/admin/users/admins');
            },
            (err) => {
              this.disabledAdd = false;

              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
        } else {
          if (this.body.password == this.body.confermedPassword) {
            delete this.body.confermedPassword;
           
            this.$http.put(`users/${this.id}`, this.body).then(
              () => {
                this.$toast.add({
                  severity: 'success',
                  summary: 'تم بنجاح',
                  detail: 'تم التعديل بنجاح',
                  life: 3000,
                });
                this.disabledAdd = false;

                // this.$router.push('/admin/users/admins');
              },
              (err) => {
                this.disabledAdd = false;

                this.$toast.add({
                  severity: 'error',
                  summary: 'هناك خطأ',
                  detail: err.response.data.message,
                  life: 3000,
                });
              },
            );
          } else {
            this.isInvalid = true;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: 'تأكيد كلمه المرور غير مطابق',
              life: 3000,
            });
          }
        }
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: 'يرجي اضافة الحقول المطلوبه',
          life: 3000,
        });
      }
    },
  },
  created() {
    const user = JSON.parse(localStorage.admin);

    this.id = user.id;
    this.$http.get(`users/${this.id}`).then(
      (res) => {
        this.body.name = res.data.name;
        this.body.userName = res.data.userName;
        this.body.email = res.data.email;

        this.body.phone = res.data.phone;
        this.body.userNotes = res.data.userNotes;
      },
      (err) => {
        this.$toast.add({
          severity: 'error',
          summary: 'هناك خطأ',
          detail: err.response.data.message,
          life: 3000,
        });
      },
    );
  },
};
</script>

<style></style>

<style>
.branches label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
